import { ReactNode } from 'react';
import { Panel, PanelGroup } from 'react-ui-kit-exante';

import { NoDataWrapper } from '~/shared/components';

import { IDataAvailabilityState } from '../types';

import UserFormTemplateStyles from './UserFormTemplate.module.css';

interface IUserFormTemplateProps {
  clients: ReactNode;
  dataAvailabilityState: IDataAvailabilityState;
  general: ReactNode;
  groupSettings: ReactNode;
  header: ReactNode;
  masterUsers: ReactNode;
  originatorIds: ReactNode;
  routingTags: ReactNode;
  subUsers: ReactNode;
}

export const UserFormTemplate = ({
  clients,
  dataAvailabilityState,
  general,
  groupSettings,
  header,
  masterUsers,
  originatorIds,
  routingTags,
  subUsers,
}: IUserFormTemplateProps) => {
  return (
    <>
      {header}

      <PanelGroup panelsPerLine={2}>
        <Panel title="General">
          <NoDataWrapper hasData={dataAvailabilityState.hasGeneralSettingsData}>
            {general}
          </NoDataWrapper>
        </Panel>

        <div className={UserFormTemplateStyles.SubPanel}>
          <NoDataWrapper hasData={dataAvailabilityState.hasGeneralSettingsData}>
            {originatorIds}
          </NoDataWrapper>
          {clients}
          {subUsers}
          {masterUsers}
          <NoDataWrapper hasData={dataAvailabilityState.hasGeneralSettingsData}>
            {routingTags}
          </NoDataWrapper>
          {groupSettings}
        </div>
      </PanelGroup>
    </>
  );
};
