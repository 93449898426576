import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  useGetCurrentUserAccessRightsQuery,
  useUpdateAccountMutation,
} from '~/api';
import { usePrevious } from '~/hooks';
import { accountPageContext } from '~/pages/AccountPage/context';
import { TAccountRegularCommissionsTableRow } from '~/resources/regularCommissions/types';
import { FixedLoader, SaveButton, TabContentHeader } from '~/shared/components';
import { OriginatorIds } from '~/shared/components/OriginatorIds';
import { RoutingTagsForm } from '~/shared/components/RoutingTagsForm';
import {
  createFormSubmitHandler,
  transformCurrenciesToSelectOptionsWithIcon,
} from '~/shared/utils';
import { selectCurrenciesWithIcon } from '~/store/currencies';
import { TRebateAccount } from '~/types/accounts';

import { RebatesContainer } from '../../RebatesContainer';
import { ReferralsContainer } from '../../ReferralsContainer';
import { RegularCommissionsContainer } from '../../RegularCommissionsContainer';
import { AccountSettings } from '../AccountSettings';
import { AvailableCurrenciesForm } from '../AvailableCurrenciesForm';
import { GeneralSettings } from '../GeneralSettings';
import { GroupSettings } from '../GroupSettings';
import { RiskSettings } from '../RiskSettings';
import { TFormData } from '../types';
import { getValidationSchema } from '../validationSchema';

import { useHandlersMap } from './useHandlersMap';

interface IAccountSettingsFormProps extends TFormData {
  refresh: (refreshList: Set<string>) => Promise<unknown>;
}

export const AccountSettingsForm = ({
  refresh,
  ...formData
}: IAccountSettingsFormProps) => {
  const { account } = formData;
  const prevFormData = usePrevious(formData);

  const { data: currentUserPermissions } = useGetCurrentUserAccessRightsQuery();
  const hasWriteSettingsAccess = currentUserPermissions?.write['Account info'];

  const currenciesOptions = useSelector(selectCurrenciesWithIcon);
  const { setDirtyTabs } = useContext(accountPageContext);

  const previousRegularCommissions = useRef<
    TAccountRegularCommissionsTableRow[] | undefined
  >(formData.regularCommissions);
  const previousRebates = useRef<TRebateAccount[] | undefined | null>(
    formData.rebates,
  );
  const previousGroupSettings = useRef(formData.groupSettings);

  const validationSchema = useMemo(() => getValidationSchema(), []);

  const formInstance = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { dirtyFields, isDirty, isSubmitting },
    getValues,
    handleSubmit,
    reset,
  } = formInstance;

  // formState should be in object for useEffect https://react-hook-form.com/docs/useform/formstate
  const wrapperFormState = { formState: formInstance.formState };

  const [updateAccount] = useUpdateAccountMutation();

  const currenciesSelectOptions = useMemo(
    () => transformCurrenciesToSelectOptionsWithIcon(currenciesOptions),
    [currenciesOptions],
  );

  const handlersMap = useHandlersMap({
    account,
    previousRegularCommissions,
    previousRebates,
    previousGroupSettings,
  });

  const onSubmitHandler = useCallback(
    async (data: TFormData) => {
      if (!hasWriteSettingsAccess) {
        return;
      }

      const formSubmitHandler = createFormSubmitHandler<TFormData>({
        dirtyFields,
        handlersMap,
        reset,
        getValues,
      });

      const { newState: response, refetchList } = await formSubmitHandler(data);

      if (response.account) {
        const { account: updatedAccount } = response;

        await updateAccount({
          ...updatedAccount,
          accountId: updatedAccount.id,
        });
      }

      await refresh(refetchList);
    },
    [
      dirtyFields,
      getValues,
      handlersMap,
      hasWriteSettingsAccess,
      refresh,
      reset,
      updateAccount,
    ],
  );

  useEffect(() => {
    setDirtyTabs('settings', wrapperFormState.formState.isDirty);
  }, [setDirtyTabs, wrapperFormState.formState.isDirty]);

  useEffect(() => {
    if (!isEqual(prevFormData, formData)) {
      reset(formData);
    }
  }, [formData, prevFormData, reset]);

  if (!account) {
    return <div>no account</div>;
  }

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        {isSubmitting && <FixedLoader />}

        <AccountSettings
          availableCurrencies={
            <AvailableCurrenciesForm
              currenciesSelectOptions={currenciesSelectOptions}
            />
          }
          header={
            <TabContentHeader
              title="Settings"
              actions={
                hasWriteSettingsAccess ? (
                  <SaveButton
                    type="submit"
                    disabled={isSubmitting || !isDirty}
                  />
                ) : undefined
              }
            />
          }
          general={
            <GeneralSettings accountId={account.id} getValues={getValues} />
          }
          groupSettings={
            <GroupSettings accountId={account.id} getValues={getValues} />
          }
          routingTags={
            <RoutingTagsForm
              accountId={account.id}
              name="routing.routingTags"
            />
          }
          regularCommissions={
            <RegularCommissionsContainer accountId={account.id} />
          }
          rebates={<RebatesContainer accountId={account.id} />}
          referrals={<ReferralsContainer accountId={account.id} />}
          risk={<RiskSettings accountId={account.id} getValues={getValues} />}
          originatorIds={
            <OriginatorIds accountId={account.id} path="account" />
          }
        />
      </form>
    </FormProvider>
  );
};
