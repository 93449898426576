import { Table } from 'react-ui-kit-exante';

import { useGetAllExpiredInstrumentsQuery } from '~/api/expiredInstruments/expiredInstruments.api';
import { PrepareTableData } from '~/pages/ExpiredInstruments/types';
import { getTableId } from '~/shared/utils';

import { getColumns } from './columns';
import { SaveExpiredInstrumentsButton } from './components';

export const ExpiredInstruments = () => {
  const { data, isLoading, isFetching } = useGetAllExpiredInstrumentsQuery();

  const additionalActions = [
    {
      component: <SaveExpiredInstrumentsButton />,
    },
  ];

  const result = (data || []).reduce(
    (acc: PrepareTableData[], { symbolId, optionGroupId, allowedSymbols }) => {
      if (
        acc
          .map((item: { optionGroupId?: string | null }) => item?.optionGroupId)
          .includes(optionGroupId)
      ) {
        return acc;
      }
      return [
        ...acc,
        {
          symbolId: symbolId || null,
          action: '',
          closePrice: 0,
          convertInto: null,
          underlyingPrice: 0,
          takeCommission: false,
          optionGroupId: optionGroupId || null,
          allowedSymbols: allowedSymbols || [],
          type: optionGroupId ? 'optionGroup' : 'symbol',
        },
      ];
    },
    [],
  );

  return (
    <Table
      tableId={getTableId('ExpiredInstruments')}
      data={result}
      columns={getColumns()}
      hasFilters
      isLoading={isLoading || isFetching}
      showTableInfo
      isFlexLayout
      hasPagination
      title="Expired Instruments"
      additionalActions={additionalActions}
    />
  );
};
