import { useMemo } from 'react';

import { AccountActivityContainer } from '~/containers/AccountActivityContainer';
import { AccountSettingsContainer } from '~/containers/AccountSettingsContainer';
import { AccountSummaryContainer } from '~/containers/AccountSummaryContainer';
import { CardsManagementWithData } from '~/containers/CardsManagementContainer/CardsManagementWithData';
import { MarginStructureContainer } from '~/containers/MarginStructureContainer';
import { UsersPermissionContainer } from '~/containers/UsersPermissionContainer';
import { usePickUserPermissions } from '~/hooks';
import { ITab } from '~/types/tabs';

import { TTabsIds } from '../context/constants';

import { IAccountTabComponentProps } from './types';

export function useAccountTabs() {
  const userPermissions = usePickUserPermissions([
    'Account info',
    'Account info: card settings',
    'Account summary',
  ]);

  const tabs = useMemo(() => {
    let tabsArray: ITab<TTabsIds, IAccountTabComponentProps>[] = [
      {
        id: 'usersPermission',
        title: 'Users Permission',
        Component: UsersPermissionContainer,
      },
      {
        id: 'activity',
        title: 'Activity',
        Component: AccountActivityContainer,
      },
    ];

    if (userPermissions['Account summary'].read) {
      const summaryTab: ITab<TTabsIds, IAccountTabComponentProps> = {
        id: 'summary',
        title: 'Summary',
        Component: AccountSummaryContainer,
      };

      const marginTab: ITab<TTabsIds, IAccountTabComponentProps> = {
        id: 'margin',
        title: 'Margin',
        Component: MarginStructureContainer,
      };

      tabsArray = [summaryTab, marginTab, ...tabsArray];
    }

    if (userPermissions['Account info'].read) {
      const settingsTab: ITab<TTabsIds, IAccountTabComponentProps> = {
        id: 'settings',
        title: 'Settings',
        Component: AccountSettingsContainer,
      };

      // we should insert settings tab before users permission tab and after margin tab
      const userPermissionTabIndex = tabsArray.findIndex(
        (tab) => tab.id === 'usersPermission',
      );

      tabsArray.splice(userPermissionTabIndex, 0, settingsTab);
    }

    if (userPermissions['Account info: card settings'].read) {
      const cardTab: ITab<TTabsIds, IAccountTabComponentProps> = {
        id: 'cards',
        title: 'Cards',
        Component: CardsManagementWithData,
      };

      // we should insert card tab before users permission tab and after settings tab
      const userPermissionTabIndex = tabsArray.findIndex(
        (tab) => tab.id === 'usersPermission',
      );

      tabsArray.splice(userPermissionTabIndex, 0, cardTab);
    }

    return tabsArray;
  }, [userPermissions]);

  const activityTabIndex = useMemo(
    () => tabs.findIndex((tab) => tab.id === 'activity'),
    [tabs],
  );

  return { tabs, activityTabIndex };
}
