import { useEffect, useMemo } from 'react';
import { useData } from 'react-ui-kit-exante';

import { useRefreshActiveTab } from '~/pages/AccountPage/hooks';

import { accountUsersService } from '../../resources';
import { useAccountSettingsData } from '../AccountSettingsContainer/useAccountSettingsData';

import { Loader, Wrapper } from './components';
import { UsersPermissionFormProvider } from './containers';

interface IUsersPermissionContainerProps {
  accountId: string;
}

export const UsersPermissionContainer = ({
  accountId,
}: IUsersPermissionContainerProps) => {
  const tableDataOptions = useMemo(
    () => ({
      onFetch: () => accountUsersService.resolveUsersPermission(accountId),
    }),
    [accountId],
  );
  const { data, fetchData, isLoading, error } = useData(tableDataOptions);
  const {
    queries: { accountQuery },
  } = useAccountSettingsData(accountId);
  const { setRefreshFn } = useRefreshActiveTab();

  const accountStatus = useMemo(() => {
    const DEFAULT_STATUS_LABEL = '';
    const { data: accountData } = accountQuery;

    if (!accountData) {
      return DEFAULT_STATUS_LABEL;
    }
    const { status } = accountData;
    return status;
  }, [accountQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setRefreshFn('usersPermission', {
      refetch: fetchData,
      isLoading,
    });
  }, [fetchData, isLoading]);

  if (isLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (!data || error) {
    return null;
  }

  return (
    <Wrapper>
      <UsersPermissionFormProvider
        accountId={accountId}
        initial={{ users: data, status: accountStatus }}
      />
    </Wrapper>
  );
};
