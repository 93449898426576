import { formatCurrency, defaultLocaleFormatter } from '~/shared/utils';
import { IPosition } from '~/types/accounts';

export const getAdditionalColumns = (
  currency: string,
  displayLock: boolean,
) => {
  if (!displayLock) {
    return [[], []];
  }

  return [
    [
      {
        Header: 'Locked Value',
        accessor: (row: IPosition) =>
          row?.lock?.reduce((acc, { amount }) => acc + amount, 0),
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
        formatting: 'number',
      },
      {
        Header: `Converted Locked Value, ${currency}`,
        accessor: (row: IPosition) =>
          row?.lock?.reduce(
            (acc, { convertedAmount }) => acc + (convertedAmount || 0),
            0,
          ),
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
        formatting: 'number',
        width: 185,
      },
    ],
    [
      {
        Header: 'Locked Quantity',
        accessor: (row: IPosition) =>
          row?.lock?.reduce((acc, { amount }) => acc + amount, 0),
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
        formatting: 'number',
      },
    ],
  ];
};
