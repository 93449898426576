import { useEffect, useRef } from 'react';

import { useLogHandleTime } from '~/hooks';
import type { IUserTabComponentProps } from '~/pages/UserPage/TabManagement/types';
import { useUserPageContextManager } from '~/pages/UserPage/hooks';
import { StaticCenteredLoader } from '~/shared/components';

import { SettingsFormContainer } from './SettingsFormContainer';
import { useGeneralSettingsData } from './useGeneralSettingsData';

export const UserGeneralSettingsContainer = ({
  userId,
}: IUserTabComponentProps) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'user-info-entry-screen',
  );

  const { setRefreshFn } = useUserPageContextManager();
  setStartHandleTime();

  const blockResendLogger = useRef(false);

  const {
    queries: {
      userGroupSettingsQuery,
      masterUsersQuery,
      subUsersQuery,
      userClientsQuery,
      userQuery,
    },
    isDataLoading,
    dataAvailabilityState,
  } = useGeneralSettingsData(userId);

  useEffect(() => {
    const queries = [
      userGroupSettingsQuery,
      masterUsersQuery,
      subUsersQuery,
      userClientsQuery,
      userQuery,
    ];
    setRefreshFn('general', {
      refetch: () => Promise.all(queries.map(({ refetch }) => refetch())),
      isLoading: queries.some(({ isFetching }) => isFetching),
    });
  }, [
    masterUsersQuery,
    setRefreshFn,
    subUsersQuery,
    userClientsQuery,
    userGroupSettingsQuery,
    userQuery,
  ]);

  useEffect(() => {
    if (!blockResendLogger.current && userQuery.data && !isDataLoading) {
      blockResendLogger.current = true;

      logHandleTime();
    }
  }, [userQuery, isDataLoading, logHandleTime, blockResendLogger]);

  if (isDataLoading) {
    return <StaticCenteredLoader withBackground height="420px" />;
  }

  return (
    <SettingsFormContainer
      user={userQuery.data}
      userClients={userClientsQuery.data}
      subUsers={subUsersQuery.data}
      masterUsers={masterUsersQuery.data}
      groupSettings={userGroupSettingsQuery.data}
      dataAvailabilityState={dataAvailabilityState}
    />
  );
};
